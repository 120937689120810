import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Homepage from "../HomePage/HomePage";
// import HomePage2 from "../HomePage2/HomePage2";
// import HomePage3 from "../HomePage3/HomePage3";
// import HomePage4 from "../HomePage4/HomePage4";
// import HomePage5 from "../HomePage5/HomePage5";
import AboutPage from "../AboutPage/AboutPage";
import ServicePage from "../ServicePage/ServicePage";
// import ServicePageS2 from "../ServicePageS2/ServicePageS2";
// import ServicePageS3 from "../ServicePageS3/ServicePageS3";
// import ServiceSinglePage from "../ServiceSinglePage/ServiceSinglePage";
import ProjectPage from "../ProjectPage/ProjectPage";
// import ProjectPageS2 from "../ProjectPageS2/ProjectPageS2";
// import ProjectSinglePage from "../ProjectSinglePage/Guatemala";
// import TeamPage from "../TeamPage/TeamPage";
// import TeamSinglePage from "../TeamSinglePage/TeamSinglePage";
// import ShopPage from "../ShopPage";
// import ProductSinglePage from "../ProductSinglePage";
// import CartPage from "../CartPage";
// import CheckoutPage from "../CheckoutPage";
// import OrderRecived from "../OrderRecived";
// import BlogPage from "../BlogPage/BlogPage";
// import BlogPageLeft from "../BlogPageLeft/BlogPageLeft";
// import BlogPageFullwidth from "../BlogPageFullwidth/BlogPageFullwidth";
// import BlogDetails from "../BlogDetails/BlogDetails";
// import BlogDetailsLeftSiide from "../BlogDetailsLeftSiide/BlogDetailsLeftSiide";
// import BlogDetailsFull from "../BlogDetailsFull/BlogDetailsFull";
import ContactPage from "../ContactPage/ContactPage";
// import TestimonialPage from "../TestimonialPage/TestimonialPage";
import ErrorPage from "../ErrorPage/ErrorPage";

import ElSalvador from "../ProjectSinglePage/ElSalvador";
import Guatemala from "../ProjectSinglePage/Guatemala";
import IslaMargarita from "../ProjectSinglePage/IslaMargarita";
import RepublicaDominicana from "../ProjectSinglePage/RepublicaDominicana";

import InvestigationDeMercado from "../Services/InvestigacionDeMercado";
import EstrategiaDeInversion from "../Services/EstrategiaDeInversion";
import NegociacionDueDiligence from "../Services/NegociacionDueDiligence";
import AsesoramientoLegal from "../Services/AsesoramientoLegal";
import AsesoramientoFinanciero from "../Services/AsesoramientoFinanciero";
import SupervisionContinua from "../Services/SupervisionContinua";
import CostaRica from "../ProjectSinglePage/CostaRica";
import PoliticaDePrivacidad from "../Legal/PoliticaDePrivacidad";
import AvisoLegal from "../Legal/AvisoLegal";
import Argentina from "../ProjectSinglePage/Argentina";
import Paraguay from "../ProjectSinglePage/Paraguay";

const AllRoute = () => {
  return (
    <div className="App">
      <BrowserRouter
        future={{
          v7_startTransition: true,
          v7_relativeSplatPath: true,
        }}
      >
        <Routes>
          <Route path="*" element={<ErrorPage />} />
          <Route path="/" element={<Homepage />} />
          {/* <Route path="home" element={<Homepage />} /> */}
          {/* <Route path="home-2" element={<HomePage2 />} />
          <Route path="home-3" element={<HomePage3 />} />
          <Route path="home-4" element={<HomePage4 />} />
          <Route path="home-5" element={<HomePage5 />} /> */}
          <Route path="quienessomos" element={<AboutPage />} />
          <Route path="servicios" element={<ServicePage />} />
          {/* <Route path="services-s2" element={<ServicePageS2 />} />
          <Route path="service-s3" element={<ServicePageS3 />} /> */}
          <Route
            path="servicios/investigacion-de-mercado"
            element={<InvestigationDeMercado />}
          />
          <Route
            path="servicios/estrategia-de-inversion"
            element={<EstrategiaDeInversion />}
          />

          <Route
            path="servicios/negociacion-due-diligence"
            element={<NegociacionDueDiligence />}
          />

          <Route
            path="servicios/asesoramiento-legal"
            element={<AsesoramientoLegal />}
          />
          <Route
            path="servicios/asesoramiento-financiero"
            element={<AsesoramientoFinanciero />}
          />
          <Route
            path="servicios/supervision-continua"
            element={<SupervisionContinua />}
          />
          <Route path="oportunidades" element={<ProjectPage />} />
          {/* <Route path="projects-s2" element={<ProjectPageS2 />} /> */}

          <Route path="oportunidades/el-salvador" element={<ElSalvador />} />
          <Route path="oportunidades/guatemala" element={<Guatemala />} />
          <Route path="oportunidades/costa-rica" element={<CostaRica />} />
          <Route
            path="/oportunidades/isla-margarita"
            element={<IslaMargarita />}
          />
          <Route
            path="/oportunidades/republica-dominicana"
            element={<RepublicaDominicana />}
          />
          <Route path="/oportunidades/argentina" element={<Argentina />} />
          <Route path="/oportunidades/paraguay" element={<Paraguay />} />
          {/* <Route path="oportunidades/:slug" element={<ProjectSinglePage />} /> */}
          {/* <Route path="team-single/:slug" element={<TeamSinglePage />} />
          <Route path="testimonials" element={<TestimonialPage />} /> */}
          {/* <Route path="team" element={<TeamPage />} /> */}
          {/* <Route path="shop" element={<ShopPage />} /> */}
          {/* <Route path="product-single/:slug" element={<ProductSinglePage />} /> */}
          {/* <Route path="cart" element={<CartPage />} />
          <Route path="checkout" element={<CheckoutPage />} />
          <Route path="order_received" element={<OrderRecived />} /> */}
          {/* <Route path="blog" element={<BlogPage />} />
          <Route path="blog-left-sidebar" element={<BlogPageLeft />} />
          <Route path="blog-fullwidth" element={<BlogPageFullwidth />} />
          <Route path="blog-single/:slug" element={<BlogDetails />} />
          <Route
            path="blog-single-left-sidebar/:slug"
            element={<BlogDetailsLeftSiide />}
          />
          <Route
            path="blog-single-fullwidth/:slug"
            element={<BlogDetailsFull />}
          /> */}
          <Route path="contacto" element={<ContactPage />} />
          <Route
            path="/politica-de-privacidad"
            element={<PoliticaDePrivacidad />}
          />
          <Route path="/aviso-legal" element={<AvisoLegal />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default AllRoute;
