import React from "react";

import puente from "../../images/about/puente.jpg";
import localCommunity from "../../images/about/localCommunity.jpg";

const MissionVission = () => {
  return (
    <section className="mission-vision-section section-padding">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col col-xl-8 col-lg-10">
            <div className="title-area">
              <h2>
                Somos INVEST IN LATAM, líderes en la atracción directa de
                inversiones en América Latina.{" "}
              </h2>
              <p>
                Con más de 7 años de experiencia, nos especializamos en conectar
                inversionistas visionarios con oportunidades de crecimiento
                excepcionales en esta vibrante región del mundo.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col col-xs-12">
            <div className="mission-vision-grids clearfix">
              <div className="grid">
                <div className="img-holder">
                  <img src={puente} alt="" />
                </div>
                <h3>Nuestra Misión</h3>
                <p>
                  Nos dedicamos a impulsar el desarrollo sostenible en
                  Latinoamérica a través de inversiones estratégicas. Nuestra
                  misión es ser el puente confiable entre inversores globales y
                  proyectos locales innovadores, facilitando el progreso
                  económico mutuo y el fortalecimiento del tejido empresarial de
                  la región, influyendo directamente en la creación de empleo y
                  riqueza, fuente del bienestar social del país.
                </p>
              </div>
              <div className="grid">
                <div className="img-holder">
                  <img src={localCommunity} alt="" />
                </div>
                <h3>Nuestra Visión</h3>
                <p>
                  Imaginamos un futuro en el que las inversiones contribuyan no
                  solo al crecimiento económico, sino también al bienestar de
                  las comunidades locales. Queremos ser reconocidos como el
                  socio preferido para aquellos que buscan oportunidades de
                  inversión significativas y sostenibles en Latinoamérica.
                </p>
              </div>
              {/* <div className="grid">
                <div className="img-holder">
                  <img src={abimg3} alt="" />
                </div>
                <h3>Our strategies</h3>
                <p>
                  He thought. It wasn't a dream. His room, a proper human room
                  although a little too small, lay peacefully between its four
                  familiar walls. A collection of textile samples lay spread out
                  on the table
                </p>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MissionVission;
