import React, { Fragment } from "react";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Footer from "../../components/footer/Footer";
import Logo from "../../images/logo.png";

import NavOportunidades from "../../components/NavOportunidad/NavOportunidades";

import { oportunidades } from "../../api/oportunidades";

const CostaRica = (props) => {
  const oportunidad = oportunidades.find((item) => item.pais === "Costa Rica");
  const index = oportunidades.findIndex((item) => item.pais === "Costa Rica");

  const imageGuatemala = {
    src: oportunidad.img,
    alt: oportunidad.alt,
  };
  return (
    <Fragment>
      <Navbar hclass={"header-style-1"} Logo={Logo} />
      <PageTitle
        pageTitle={"Costa Rica"}
        pagesub={"Costa Rica"}
        image={imageGuatemala}
      />
      <section className="project-sigle-section section-padding">
        <div className="container">
          <div className="row">
            <div className="col col-xs-12">
              <div className="project-single-content">
                <div className="img-holder">
                  <img src={oportunidad.secondimg} alt="" />
                </div>
                <div className="content-area">
                  <div className="project-info">
                    <ul>
                      <li>
                        <span>Dato relevante:</span> xxxxxxxxxxxx
                      </li>
                      <li>
                        <span>Dato relevante:</span> xxxxxxxxxxxx
                      </li>
                      <li>
                        <span>Dato relevante:</span> xxxxxxxxxxxx
                      </li>
                      <li>
                        <span>Dato relevante:</span> xxxxxxxxxxxx
                      </li>
                      <li>
                        <span>Dato relevante:</span> xxxxxxxxxxxx
                      </li>
                    </ul>
                  </div>
                  {/* {oportunidad.sectores.map((op, id) => {
                    return (
                      <SectorConPotencial
                        key={id}
                        title={op.sector}
                        textoPotencial={op.potencialDeCrecimiento}
                        textoDatos={op.datosRelevantes}
                      />
                    );
                  })} */}
                  <NavOportunidades index={index} />
                  {/* <div className="prev-next-project">
                    <div>
                      <Link
                        onClick={ClickHandler}
                        to={"/project-single/Investment-planning"}
                      >
                        <div className="icon">
                          <i className="fi flaticon-back"></i>
                        </div>
                        <span>Previous oportunidad</span>
                        <h5>test</h5>
                      </Link>
                    </div>
                    <div>
                      <Link
                        onClick={ClickHandler}
                        to={"/project-single/Financial-Advices"}
                      >
                        <div className="icon">
                          <i className="fi flaticon-next"></i>
                        </div>
                        <span>Siguiente oportunidad</span>
                        <h5>test</h5>
                      </Link>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default CostaRica;
