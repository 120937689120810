import React from "react";
import { Navigation, A11y, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";

import { Link } from "react-router-dom";
// import beachBlueLATAM from "../../images/slider/beachBlueLATAM.jpg";
import panama from "../../images/slider/panama.jpg";

import { oportunidades } from "../../api/oportunidades";

const Hero = () => {
  // const oportunidadSalvador = oportunidades.find(
  //   (item) => item.pais === "El Salvador"
  // );
  // const oportunidadGuatemala = oportunidades.find(
  //   (item) => item.pais === "Guatemala"
  // );

  return (
    <Swiper
      // install Swiper modules
      modules={[Navigation, A11y, Autoplay]}
      spaceBetween={0}
      slidesPerView={1}
      loop={true}
      speed={1800}
      parallax={true}
      navigation
      autoplay={{
        delay: 5000,
        disableOnInteraction: true,
      }}
    >
      <SwiperSlide>
        <section className="hero-slider hero-style-1">
          <div
            className="slide-inner slide-bg-image"
            style={{
              backgroundImage: `url(${panama})`,
            }}
          >
            <div
              className="overlay"
              style={{ background: "rgba(0,0,0,0.8)" }}
            ></div>
            <div className="container">
              <div className="slide-title">
                <h2>¡Invierte en el Futuro de América Latina!</h2>
              </div>
              <div className="slide-text">
                <p>
                  Descubre Oportunidades de Inversión Excepcionales en
                  Latinoamérica
                </p>
              </div>
              <div className="clearfix"></div>
              <div className="slide-btns">
                <Link to="/contacto" className="theme-btn">
                  Contáctanos <span></span>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </SwiperSlide>
      {oportunidades.map((op) => (
        <SwiperSlide key={op.id + 100}>
          <section className="hero-slider hero-style-1">
            <div
              className="slide-inner slide-bg-image"
              style={{ backgroundImage: `url(${op.secondimg})` }}
            >
              <div className="overlay"></div>
              <div className="container">
                <div className="slide-title">
                  <h2>{`¡Invierte en ${op.pais} !`} </h2>
                </div>
                <div className="slide-text">
                  <p>
                    {` Descubre Oportunidades de Inversión Excepcionales en ${op.pais}`}
                  </p>
                </div>
                <div className="clearfix"></div>
                <div className="slide-btns">
                  <Link to={`/oportunidades/${op.slug}`} className="theme-btn">
                    Descubrir <span></span>
                  </Link>
                </div>
              </div>
            </div>
          </section>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Hero;
