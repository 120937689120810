import React from "react";
import { Link } from "react-router-dom";

const PageTitle = (props) => {
  return (
    <section className="page-title">
      {props.image && <img src={props.image.src} alt={props.image.alt} />}
      <div className="container">
        <div className="row">
          <div className="col col-xs-12">
            <h2>{props.pageTitle}</h2>
            <ol className="breadcrumb">
              <li>
                <Link to="/">Invest in LATAM</Link>
              </li>
              <li>
                <span>{props.pagesub}</span>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PageTitle;
