import salvador from "../images/oportunidades/salvador.jpg";
import salvadorsecond from "../images/oportunidades/salvadorsecond.jpg";
import guatemala from "../images/oportunidades/guatemala.jpg";
import guatemalasecond from "../images/oportunidades/guatemalasecond.jpg";
import islaMargarita from "../images/oportunidades/Margarita_Island.webp";
import islaMargaritasecond from "../images/oportunidades/Margarita_IslandSecond.webp";
import republicaDominicana from "../images/oportunidades/republicaDominicana.webp";
import republicaDominicanaSecond from "../images/oportunidades/republicaDominicanaSecond.webp";
import argentina from "../images/oportunidades/argentina.webp";
import argentinaSecond from "../images/oportunidades/argentinaSecond.webp";
import paraguay from "../images/oportunidades/paraguay.webp";
import paraguaySecond from "../images/oportunidades/paraguaySecond.webp";

export const oportunidades = [
  {
    pais: "El Salvador",
    img: salvador,
    secondimg: salvadorsecond,
    slug: "el-salvador",
    alt: "Foto de El Salvador",
    id: 1,
    reasonsToInvest: [
      {
        id: 111,
        itemTitle: "Tratados de Libre Comercio",
        itemText:
          "El Salvador forma parte de varios tratados de libre comercio, incluyendo el Tratado de Libre Comercio entre Estados Unidos, Centroamérica y República Dominicana (CAFTA-DR), que facilitan el comercio y la inversión entre los países miembros, brindando ventajas a las empresas que operan en la región.",
      },
      {
        id: 112,
        itemTitle: "Ubicación estratégica",
        itemText:
          "El Salvador se encuentra en una ubicación geográfica estratégica en el corazón de Centroamérica. Esto puede facilitar el acceso a otros mercados de la región y servir como un centro logístico para la distribución de bienes.",
      },
      {
        id: 113,
        itemTitle: "Dolarización",
        itemText:
          "Desde el año 2001, El Salvador adoptó el dólar estadounidense como su moneda oficial. Esto elimina el riesgo cambiario y facilita las transacciones comerciales internacionales.",
      },
      {
        id: 114,
        itemTitle: "Incentivos para inversionistas",
        itemText:
          "El gobierno salvadoreño ha implementado medidas para atraer inversiones extranjeras, incluyendo incentivos fiscales y otras facilidades para las empresas que invierten en en país.",
      },
      {
        id: 115,
        itemTitle: "Desarrollo de infraestructura",
        itemText:
          "Los últimos años ha invertido en el desarrollo y mejora de infraestructuras, incluyendo carreteras, puertos y aeropuertos, lo que facilita la conectividad y movilidad de bienes y personas.",
      },
      {
        id: 116,
        itemTitle: "Sectores en auge",
        itemText:
          "Aunque no cuenta con grandes recursos naturales, El Salvador tiene una economía diversificada que incluye sectores relevantes como la agricultura, la industria manufacturera, y en pleno auge y enorme potencial como la construcción, tecnología, servicios financieros y turismo.",
      },
      {
        id: 117,
        itemTitle: "Crecimiento económico",
        itemText:
          "la economía del país está experimentando un crecimiento sostenido, lo que proporciona oportunidades para inversiones rentables.",
      },
      {
        id: 118,
        itemTitle: "Estabilidad política y macroeconómica",
        itemText:
          "La estabilidad política y macroeconómica es fundamental para atraer inversiones y El Salvador se encuentra en un periodo de estabilidad en ambos aspectos además de gozar de una gran seguridad.",
      },
      {
        id: 119,
        itemTitle: "Sector tecnológico en crecimiento",
        itemText:
          "El país está experimentando un crecimiento exponencial en el sector tecnológico con un alto potencial para inversores del sector.",
      },
    ],
    sectores: [
      {
        id: 61,
        sector: "Agricultura y Agroindustria",
        potencialDeCrecimiento:
          "Guatemala cuenta con vastas tierras fértiles y una diversidad climática que favorece la producción agrícola. Invertir en la modernización de la agricultura y la agroindustria presenta oportunidades significativas, así como agricultura ecológica y sector Agrobio.",
        datosRelevantes:
          "El sector agrícola de Guatemala ha experimentado un aumento del X% en la exportación de productos clave, como el café y los vegetales, según [fuente].",
      },
      {
        id: 62,
        sector: "Manufactura y Exportación",
        potencialDeCrecimiento:
          "Guatemala es conocida por su mano de obra calificada a costes muy competitivos. Invertir en la expansión de la capacidad manufacturera y en la exportación de productos puede generar rendimientos atractivos.",
        datosRelevantes:
          "Las exportaciones de productos manufacturados de Guatemala han aumentado en un X% en el último año, según [fuente].",
      },
      {
        id: 63,
        sector: "Turismo Cultural y Natural",
        potencialDeCrecimiento:
          "Guatemala posee una rica herencia cultural y una naturaleza exuberante con paisajes impresionantes. El turismo cultural y ecológico presenta oportunidades para inversiones en desarrollo turístico sostenible.",
        datosRelevantes:
          "El turismo en Guatemala ha experimentado un crecimiento del X% en los últimos dos años, con un aumento en la llegada de turistas internacionales, según [fuente].",
      },
    ],
  },
  {
    pais: "Guatemala",
    id: 2,
    img: guatemala,
    secondimg: guatemalasecond,
    slug: "guatemala",
    reasonsToInvest: [
      {
        id: 511,
        itemTitle: "Tratados de libre comercio",
        itemText:
          "Guatemala es parte de varios tratados de libre comercio, como el Tratado de Libre Comercio entre Centroamérica y Estados Unidos (CAFTA-DR), lo que facilita el acceso a mercados internacionales.",
      },
      {
        id: 512,
        itemTitle: "Ubicación estratégica",
        itemText:
          "Guatemala se encuentra en una ubicación estratégica en América Central y con puerto de salida y entrada de mercancías tanto a Pacifico como Caribe, lo que facilita el comercio con otros países de la región y Norteamérica.",
      },
      {
        id: 513,
        itemTitle: "Incentivos para la inversión extranjera",
        itemText:
          "El gobierno ha implementado medidas para atraer inversión extranjera, incluyendo incentivos y reducción de cargas fiscales y programas para facilitar la creación de empresas.",
      },
      {
        id: 514,
        itemTitle: "Recursos naturales",
        itemText:
          "La presencia de recursos naturales, como tierras fértiles y una variedad de productos agrícolas, puede ser atractiva para inversiones en agricultura y agroindustria.",
      },
      {
        id: 515,
        itemTitle: "Sector turístico",
        itemText:
          "Guatemala uenta con una rica herencia cultural, sitios arqueológicos y belleza natural, lo que puede atraer inversiones en el sector turístico y el desarrollo sostenible.",
      },
      {
        id: 516,
        itemTitle: "Mano de obra competitiva",
        itemText:
          "Guatemala tiene una fuerza laboral joven y muy competitiva, con costos laborales relativamente bajos en comparación con otros países.",
      },
      {
        id: 517,
        itemTitle: "Estabilidad política y económica",
        itemText:
          "La estabilidad política y económica es un factor importante para la inversión a largo plazo ya que brinda seguridad a los inversores.",
      },
      {
        id: 518,
        itemTitle: "Infraestructura en desarrollo",
        itemText:
          "Mejoras en la infraestructura, como carreteras y puertos, que facilitan la logística y el transporte de mercancías.",
      },
      {
        id: 519,
        itemTitle: "Crecimiento económico sostenido",
        itemText:
          "La economía está experimentando un crecimiento sostenido, esto podría indicar oportunidades para inversiones rentables.",
      },
    ],
    sectores: [
      {
        id: 61,
        sector: "Agricultura y Agroindustria",
        potencialDeCrecimiento:
          "Guatemala cuenta con vastas tierras fértiles y una diversidad climática que favorece la producción agrícola. Invertir en la modernización de la agricultura y la agroindustria presenta oportunidades significativas, así como agricultura ecológica y sector Agrobio.",
        datosRelevantes:
          "El sector agrícola de Guatemala ha experimentado un aumento del X% en la exportación de productos clave, como el café y los vegetales, según [fuente].",
      },
      {
        id: 62,
        sector: "Manufactura y Exportación",
        potencialDeCrecimiento:
          "Guatemala es conocida por su mano de obra calificada a costes muy competitivos. Invertir en la expansión de la capacidad manufacturera y en la exportación de productos puede generar rendimientos atractivos.",
        datosRelevantes:
          "Las exportaciones de productos manufacturados de Guatemala han aumentado en un X% en el último año, según [fuente].",
      },
      {
        id: 63,
        sector: "Turismo Cultural y Natural",
        potencialDeCrecimiento:
          "Guatemala posee una rica herencia cultural y una naturaleza exuberante con paisajes impresionantes. El turismo cultural y ecológico presenta oportunidades para inversiones en desarrollo turístico sostenible.",
        datosRelevantes:
          "El turismo en Guatemala ha experimentado un crecimiento del X% en los últimos dos años, con un aumento en la llegada de turistas internacionales, según [fuente].",
      },
    ],
  },
  {
    pais: "Isla Margarita",
    id: 3,
    img: islaMargarita,
    secondimg: islaMargaritasecond,
    slug: "isla-margarita",
  },
  {
    pais: "República Dominicana",
    id: 4,
    img: republicaDominicana,
    secondimg: republicaDominicanaSecond,
    slug: "republica-dominicana",
  },
  {
    pais: "Argentina",
    id: 5,
    img: argentina,
    secondimg: argentinaSecond,
    slug: "argentina",
  },
  {
    pais: "Paraguay",
    id: 6,
    img: paraguay,
    secondimg: paraguaySecond,
    slug: "paraguay",
  },
  // {
  //   pais: "Costa Rica",
  //   id: 3,
  //   img: guatemala,
  //   secondimg: guatemalasecond,
  //   slug: "costa-rica",
  //   reasonsToInvest: [
  //     {
  //       id: 311,
  //       itemTitle: "Tratados de libre comercio",
  //       itemText:
  //         "Guatemala es parte de varios tratados de libre comercio, como el Tratado de Libre Comercio entre Centroamérica y Estados Unidos (CAFTA-DR), lo que facilita el acceso a mercados internacionales.",
  //     },
  //     {
  //       id: 312,
  //       itemTitle: "Ubicación estratégica",
  //       itemText:
  //         "Guatemala se encuentra en una ubicación estratégica en América Central y con puerto de salida y entrada de mercancías tanto a Pacifico como Caribe, lo que facilita el comercio con otros países de la región y Norteamérica.",
  //     },
  //     {
  //       id: 313,
  //       itemTitle: "Incentivos para la inversión extranjera",
  //       itemText:
  //         "El gobierno ha implementado medidas para atraer inversión extranjera, incluyendo incentivos y reducción de cargas fiscales y programas para facilitar la creación de empresas.",
  //     },
  //     {
  //       id: 314,
  //       itemTitle: "Recursos naturales",
  //       itemText:
  //         "La presencia de recursos naturales, como tierras fértiles y una variedad de productos agrícolas, puede ser atractiva para inversiones en agricultura y agroindustria.",
  //     },
  //     {
  //       id: 313,
  //       itemTitle: "Sector turístico",
  //       itemText:
  //         "Guatemala cuenta con una rica herencia cultural, sitios arqueológicos y belleza natural, lo que puede atraer inversiones en el sector turístico y el desarrollo sostenible.",
  //     },
  //     {
  //       id: 316,
  //       itemTitle: "Mano de obra competitiva",
  //       itemText:
  //         "Guatemala tiene una fuerza laboral joven y muy competitiva, con costos laborales relativamente bajos en comparación con otros países.",
  //     },
  //     {
  //       id: 317,
  //       itemTitle: "Estabilidad política y económica",
  //       itemText:
  //         "La estabilidad política y económica es un factor importante para la inversión a largo plazo ya que brinda seguridad a los inversores.",
  //     },
  //     {
  //       id: 318,
  //       itemTitle: "Infraestructura en desarrollo",
  //       itemText:
  //         "Mejoras en la infraestructura, como carreteras y puertos, que facilitan la logística y el transporte de mercancías.",
  //     },
  //     {
  //       id: 519,
  //       itemTitle: "Crecimiento económico sostenido",
  //       itemText:
  //         "La economía está experimentando un crecimiento sostenido, esto podría indicar oportunidades para inversiones rentables.",
  //     },
  //   ],
  // },
];
