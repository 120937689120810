import React, { Fragment } from "react";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
// import { useParams } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import Logo from "../../images/logo.png";

import { oportunidades } from "../../api/oportunidades";
// import SectorConPotencial from "../../components/sector/SectorConPotencial";
import NavOportunidades from "../../components/NavOportunidad/NavOportunidades";

const Argentina = (props) => {
  // const { slug } = useParams();

  const oportunidad = oportunidades.find((item) => item.pais === "Argentina");
  const index = oportunidades.findIndex((item) => item.pais === "Argentina");

  // const ClickHandler = () => {
  //   window.scrollTo(10, 0);
  // };

  const image = {
    src: oportunidad.img,
    alt: oportunidad.alt,
  };

  return (
    <Fragment>
      <Navbar hclass={"header-style-1"} Logo={Logo} />
      <PageTitle
        pageTitle={"¿ Por qué invertir en Argentina ?"}
        pagesub={oportunidad.pais}
        image={image}
      />
      <section className="project-sigle-section section-padding">
        <div className="container">
          <div className="row">
            <div className="col col-xs-12">
              <div className="project-single-content">
                <div className="img-holder">
                  <img src={oportunidad.secondimg} alt="" />
                </div>
                <div className="content-area">
                  <section>
                    <h2>En construcción</h2>
                  </section>
                  <NavOportunidades index={index} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default Argentina;
